import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/contact.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import telephone from "../assets/icons/telephone-call.png";
import email from "../assets/icons/email.png";
import meeting from "../assets/icons/meeting.png";
import support from "../assets/icons/help-web-button.png";
import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import twitter from "../assets/icons/twitter.png";
import youtube from "../assets/icons/youtube.png";
import linkedin from "../assets/icons/linkedin.png";
import profile from "../assets/icons/profile.png";
import growth from "../assets/icons/growth-chart.png";
import quote from "../assets/icons/quote.png";
import loaderGif from "../assets/fuzzads-loader.gif";
import { motion } from "framer-motion";

const Contact = ({ scrollToDemo }) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 }, // Start hidden below the original position
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }, // Fade in to the original position
  };
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleWhatsAppClick = () => {
    const phoneNumber = "+919310930177";
    const message = "Hello, I want to enquire about ";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappURL, "_blank");
  };

  
  const handleBookMeetingClick = () => {
    // Navigate to the homepage and pass state to indicate scroll to Freedemo
    navigate("/", { state: { scrollToDemo: true } });
  };

  const handleServiceClick = () => {
    navigate("/services");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      name: e.target[0].value,
      phone: phone,
      email: e.target[2].value,
      businessName: e.target[3].value,
      businessDescription: e.target[4].value,
      query: e.target[5].value,
    };

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        alert("Contact form submitted successfully!");
        navigate("/thank-you");
      } else {
        alert("Failed to submit the form, please try again.");
        navigate("/error");
      }

      e.target.reset();
      setPhone("");
    } catch (error) {
      console.error("Error:", error);
      alert("Something went wrong. Please try again.");
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <img src={loaderGif} alt="Loading..." className="loader" />
        </div>
      )}
      <div className={`contact-container ${loading ? "blur" : ""}`}>
        <div className="contact-video">
          <h2 style={{ textAlign: "center", color: "#FFFFFF" }}>Contact Us</h2>
        </div>
        <div className="contact-content">
          <h2 style={{ fontWeight: "bold" }}>
            Talk to Us—We’re{" "}
            <span style={{ color: "#00a5e0" }}>Here to Help</span>
          </h2>
        </div>

        <motion.div className="contact-boxes" initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}>
          <div className="box">
            <img src={telephone} alt="Box 1" className="box-image" />
            <p>+91 9310930177</p>
            <p style={{ color: "#00a5e0" }}>Contact us/WhatsApp</p>
          </div>
          <div className="box">
            <img src={email} alt="Box 2" className="box-image" />
            <p style={{ textDecoration: "underline" }}>support@fuzzads.com</p>
            <p style={{ color: "#00a5e0" }}>Email Address</p>
          </div>
          <div className="box">
            <img src={meeting} alt="Box 3" className="box-image" />
            <p>Connect Us with Meet</p>
            <p style={{ color: "#00a5e0", fontSize: "1.1rem" }}>
              Mon - Fri: 10:00am - 10:00pm
            </p>
          </div>
          <div className="box">
            <img src={support} alt="Box 4" className="box-image" />
            <p>Expert Help support</p>
            <p style={{ color: "#00a5e0" }}>24/7 Everyday</p>
          </div>
        </motion.div>
        <div className="main-contact">
          <motion.div className="left-contact" initial="hidden"
            whileInView="visible"
            variants={leftToRightVariant}
            viewport={{ once: true }}>
            <h2 style={{ textAlign: "left" }}>Lets Connect to Grow</h2>
            <p style={{ color: "#00a5e0", fontWeight: "bold" }}>
              Get in touch with us! Whether you have questions, need support, or
              want to discuss your next project, we're here to help. Fill out
              the form and we'll get back to you soon!
            </p>
            <form className="maincontact-form" onSubmit={handleSubmit}>
              <div className="contactform-group">
                <label>
                  NAME
                  <input type="text" placeholder="Name*" required style={{marginTop: '5px'}}/>
                </label>
              </div>
              <div className="contactform-group">
                <label htmlFor="phone">PHONE</label>
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  country={"in"}
                  id="phone"
                  name="phone"
                  style={{marginTop: '5px'}}
                  inputStyle={{
                    width: "100%",
                    height: "2.7rem",
                    padding: "10px",
                    marginBottom: "25px",
                    borderRadius: "50px",
                    border: "3px solid #26017b",
                    fontSize: "1rem",
                    paddingLeft: "65px",
                  }}
                  buttonStyle={{
                    borderRadius: "50px 0 0 50px",
                    border: "3px solid #26017b",
                    marginRight: "10px",
                    outline: "none", // Disables outline on flag click
                    boxShadow: "none", // Removes any focus shadow on the flag
                    backgroundColor: "transparent"
                  }}
                  containerClassName="custom-phone-input-container"
                  required
                />
              </div>

              <div className="contactform-group" style={{ marginTop: "20px" }}>
                <label>
                  EMAIL
                  <input type="email" placeholder="Email ID*" required style={{marginTop: '5px'}} />
                </label>
              </div>
              <div className="contactform-group">
                <label>
                  BUSINESS NAME
                  <input
                    type="text"
                    placeholder="Enter Business Name*"
                    required
                    style={{marginTop: '5px'}}
                  />
                </label>
              </div>

              <div className="contactform-group">
                <label>
                  BUSINESS DESCRIPTION
                  <input
                    type="text"
                    placeholder="Enter Business Description*"
                    style={{marginTop: '5px'}}
                    required
                  />
                </label>
              </div>
              <div className="contactform-group">
                <label>
                  QUERY
                  <textarea
                    style={{ borderRadius: "20px", marginTop: '5px' }}
                    placeholder="Give a short description about your Query"
                    
                    required
                  ></textarea>
                </label>
              </div>

              <button type="submit" className="main-submit">
                Let's Grow <img src={growth} alt="growth-chart" />
              </button>
            </form>
          </motion.div>

          <motion.div className="right-contact" initial="hidden"
            whileInView="visible"
            variants={rightToLeftVariant}
            viewport={{ once: true }} >
            <div className="quick-links">
              <h2>Quick Links </h2>
              <button onClick={handleBookMeetingClick}>Book a Meeting</button>
              <button onClick={handleServiceClick}>Our Services</button>
              <button onClick={handleWhatsAppClick}>
                Connect us on Whatsapp
              </button>
              <button>Social Links</button>
            </div>
            <div className="contactsocial-links">
              <img src={facebook} alt="facebook" />
              <img src={instagram} alt="instagram" />
              <img src={twitter} alt="twitter" />
              <img src={youtube} alt="youtube" />
              <img src={linkedin} alt="linkedin" />
            </div>
            <div className="quotebox">
              <img src={quote} alt="quote" className="quote-img" />
              <p>
                Great brands aren't built overnight—they're crafted with
                passion, persistence, and innovation. At{" "}
                <span style={{ color: "#26017b" }}>Fuzz</span>
                <span style={{ color: "#00a5e0" }}>Ads</span>, we believe in
                pushing boundaries, embracing creativity, and driving growth.
                Success is a journey, and every step forward counts. Let's rise,
                create, and conquer together!
              </p>
              <div className="profile-container">
                <img src={profile} alt="profile" className="profile-img" />
                <div className="profile-text">
                  <h5>
                    Pratham Choudhary <br />
                    <span style={{ color: "#00a5e0", fontSize: "1rem" }}>
                      Founder/CEO
                    </span>
                  </h5>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Contact;
