import React from "react";
import "../styles/main.css";
import SignupPage from "../components/SignupComponent";

const SignupMainPage = () => {
  return (
    <>
      <div class="content-container">
        <section class="hero">
          <SignupPage />
        </section>
      </div>
    </>
  );
};

export default SignupMainPage;
