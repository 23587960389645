import React, { useState } from "react";
import "../styles/loginpage.css";
import LoginBro from "../assets/Login-bro.png"; // Image from assets
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Updated import
import googgleicon from "../assets/icons/google.png";
import loaderGif from "../assets/fuzzads-loader.gif"; // Import the loader image

const Loginpage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate(); // Updated usage

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const response = await fetch(
      "https://fuzzads-main-server.onrender.com/api/auth/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: username, password }),
      }
    );

    const data = await response.json();
    setLoading(false); // Stop loading

    if (response.ok) {
      // Store JWT token and initials in localStorage
      localStorage.setItem("token", data.token);
      localStorage.setItem("userInitials", data.initials);

      // Redirect to homepage
      navigate("/"); // Updated usage
    } else {
      alert(data.msg);
    }
  };

  return (
    <div className="fullscreen-container">
      {/* Loader Overlay */}
      {loading && (
        <div className="loader-overlay">
          <img src={loaderGif} alt="Loading..." className="loader-image" />
        </div>
      )}

      <div className="login-box">
        {/* 600x400px box */}
        {/* Left Side */}
        <div className="login-left">
          <h2 className="login-heading">LOGIN</h2>
          <p className="login-subheading">Ready to be back in Business</p>

          <form className="login-form" onSubmit={handleLogin}>
            <label htmlFor="username" className="form-label">
              Username or Phone Number
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-input"
              placeholder="Email or Phone No."
              value={username}
              style={{marginTop: '10px'}}
              onChange={(e) => setUsername(e.target.value)}
              required
            />

            <label htmlFor="password" className="form-label">
              Password
            </label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                className="form-input"
                placeholder="Enter your Password"
                value={password}
                style={{marginTop: '10px'}}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <span
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>

            <div className="options-container">
              <label className="remember-me">
                <input type="checkbox" name="remember" /> Remember me
              </label>
              <div className="forgot-password">
                <a href="/forgot-password">Forgot password?</a>
              </div>
            </div>

            <button type="submit" className="login-button">
              Login
            </button>
            <p className="or-divider" style={{ color: "white" }}>
              OR
            </p>

            <button type="submit" className="googleauth-button">
              <img src={googgleicon} alt="google" /> Continue with Google
            </button>
            <p className="signup-text" style={{ fontWeight: "bold" }}>
              Don’t have an FuzzADs Account no worries?{" "}
              <Link
                className="signup-link"
                to="/create-account"
                style={{ textDecoration: "none" }}
              >
                Sign Up Now
              </Link>
            </p>
          </form>
        </div>
        {/* Right Side */}
        <div className="login-right">
          <div className="login-content">
            <h2>
              Fuzz<span style={{ color: "#00a5e0" }}>ADs</span>
            </h2>
            <img src={LoginBro} alt="LoginBro" className="login-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loginpage;
