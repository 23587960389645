
import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../styles/main.css";
import WhatsAppIcon from "../components/WhatsappIcon";
import Error from "../components/Error";

const ErrorPage = () => {
  return (
    <>
      <header class="navbar">
        <Navbar />
      </header>

      <div class="content-container">
        <section class="hero">
          <Error />
        </section>
      </div>

      <footer class="footer">
        <Footer />
      </footer>
      <WhatsAppIcon />
    </>
  );
};

export default ErrorPage;
