import React from "react";
import "../styles/refund.css";

const ReturnRefund = () => {
  return (
    <div className="refund-container">
      <div className="refund-content">
        <h1>RETURN AND REFUND POLICY</h1>
        <p>
          <h3>1. Overview</h3>
          <p>
            FuzzAds aims to provide high-quality services to our clients. If you
            are not satisfied with our services, please review our return and
            refund policy.
          </p>
        </p>

        <p>
          <h3>2. Service Cancellation </h3>
          Clients may cancel a service within 24 hours of plan purchase to be
          eligible for a 90%refund (10% will be deducted as token amount).
          Cancellations made after this period will not be eligible for a
          refund.
        </p>

        <p>
          <h3>3. Refund Eligibility </h3>
          <p>
            Refunds are only provided for services that have not been fully
            delivered or completed. If work has commenced, a partial refund may
            be issued based on the percentage of work completed.
          </p>
        </p>
        <p>
          <h3>4. Non-Refundable Services </h3>
          <p>
            Certain services, such as logo design or software solutions, may be
            non-refundable once work has started. This will be clearly stated in
            the service agreement.
          </p>{" "}
        </p>
        <p>
          <h3>5. Refund Process </h3>
          <p>
            To request a refund, please contact us at fuzzads.co@gmail.com or
            +91 9310930177 with your order details. Refunds will be processed
            within 3 to 5 business days of receiving your request.
          </p>
        </p>
        <p>
          <h3>6. Dispute Resolution </h3>
          <p>
            If you are dissatisfied with the outcome of a refund request, please
            contact us to discuss further. We strive to resolve any issues
            amicably.
          </p>
        </p>
        <p>
          <h4>
            Note: - Payment updation could take 3 to 5 days on failure or
            pending transactions done through the respective mode of
            transactions
          </h4>
        </p>
      </div>
    </div>
  );
};

export default ReturnRefund;
