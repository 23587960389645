import React from "react";
import "../styles/about.css";

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1>About Us</h1>
        <h2>Company Overview</h2>
        <p>
          At FuzzAds, we are passionate about helping businesses thrive in the
          digital world. Founded by Pratham Choudhary, with Unnati Samaiyar as
          co-founder and head of our development department, FuzzAds is a
          full-service ad agency specializing in social media marketing, website
          development, software development and branding solutions.
        </p>
        <h3>Core Values </h3>
        <p>
          We stand as the interface between businesses and individuals with the
          digital world, focusing on social media, advertisement, and expanding
          consumer bases. Our commitment is to bridge the gap between our
          clients and the evolving digital landscape, ensuring growth and
          success in a rapidly changing environment.
        </p>
        <h3>Team Introduction </h3>
        <p>
          Our leadership team is the driving force behind our vision:
          <li>
            <strong>Pratham Choudhary - Founder and Management </strong>
          </li>
          <li>
            <strong>Unnati Samaiyar - Co-Founder and Head of Development Department </strong>
          </li>
          Together, we are committed to delivering innovative solutions and
          steering our company towards global growth.
        </p>
        <p>
          Our mission is to deliver customized digital marketing strategies that
          drive results. Whether you're a small business just getting started or
          an established brand looking to grow, we offer tailored services to
          meet your unique needs. Our plans—Silver, Gold, and Platinum—cater to
          businesses of all sizes, ensuring that you get the most value for your
          investment.
        </p>
        <h3>Unique Selling Proposition </h3>
        <p>
          We offer a one-shot solution for the digital needs of businesses, with
          a special focus on business growth and advertisement. Our
          comprehensive approach and expertise set us apart, ensuring that your
          digital presence is both powerful and effective.
        </p>
        <p>Certainly! Here's a more detailed "Call to Action": </p>
        <br />
        <h3>Call to Action </h3>
        <p>
          Are you ready to take your business to the next level? Let us help you
          navigate the complexities of the digital world with our all-in-one
          solutions tailored to meet your unique needs. Whether you're looking
          to enhance your social media presence, drive impactful advertisements,
          or manage your brand more effectively, we're here to guide you every
          step of the way.
        </p>
        <p>
          Don’t wait—reach out to us today and discover how we can partner with
          you to achieve your goals. Your success is just a conversation away.
          Let's start building your future together!
        </p>
      </div>
    </div>
  );
};

export default About;
