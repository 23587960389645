import React, { useState } from "react";
import "../styles/chooseplan.css";
import fuzzads from "../assets/icons/fuzzads.png";

const plansData = {
  silver: {
    durations: {
      "1 Month": {
        salePrice: "Rs. 9,999/-",
        finalPrice: "Rs. 5,999/-",
        discount: 40,
      },
      "1 Year": {
        salePrice: "Rs. 19,999/-",
        finalPrice: "Rs. 13,999/-",
        discount: 30,
      },
      "2 Years": {
        salePrice: "Rs. 34,999/-",
        finalPrice: "Rs. 24,999/-",
        discount: 30,
      },
    },
  },
  gold: {
    durations: {
      "1 Month": {
        salePrice: "Rs. 19,999/-",
        finalPrice: "Rs. 12,999/-",
        discount: 35,
      },
      "1 Year": {
        salePrice: "Rs. 39,999/-",
        finalPrice: "Rs. 23,999/-",
        discount: 40,
      },
      "2 Years": {
        salePrice: "Rs. 59,999/-",
        finalPrice: "Rs. 38,999/-",
        discount: 35,
      },
    },
  },
  platinum: {
    durations: {
      "1 Month": {
        salePrice: "Rs. 29,999/-",
        finalPrice: "Rs. 17,999/-",
        discount: 40,
      },
      "1 Year": {
        salePrice: "Rs. 59,999/-",
        finalPrice: "Rs. 35,099/-",
        discount: 41.5,
      },
      "2 Years": {
        salePrice: "Rs. 89,999/-",
        finalPrice: "Rs. 54,999/-",
        discount: 39,
      },
    },
  },
};

const ChoosePlan = () => {
  const [selectedPlan, setSelectedPlan] = useState("gold");
  const [selectedDuration, setSelectedDuration] = useState("1 Month");

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
    setSelectedDuration("1 Month"); // Reset duration when plan changes
  };

  const handleDurationChange = (e) => {
    setSelectedDuration(e.target.value);
  };

  const currentPlanData = plansData[selectedPlan].durations[selectedDuration];

  const parsePrice = (priceString) => {
    // Remove non-numeric characters like "Rs.", commas, spaces, and "/-"
    const cleanedString = priceString.replace(/[^0-9]/g, "");
    return Number(cleanedString);
  };

  const handleCheckout = async () => {
    try {
      const response = await fetch("http://localhost:5000/api/cashfree/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: salePriceNum,
          currency: "INR",
          customerEmail: "customer@example.com",
          customerPhone: "1234567890",
          orderId: `ORDER_${new Date().getTime()}`, // Unique order ID
        }),
      });

      const result = await response.json();
      if (result.success) {
        window.location.href = result.paymentLink; // Redirect to Cashfree payment page
      } else {
        alert("Payment initiation failed.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  // Calculate the difference between final price and sale price
  const finalPriceNum = parsePrice(currentPlanData.finalPrice);
  const salePriceNum = parsePrice(currentPlanData.salePrice);
  const priceDifference = salePriceNum - finalPriceNum;
  return (
    <>
      <div className="chooseplan-container">
        <div className="chooseplan-box">
          <div className="chooseplan-top">
            {/*<img src={fuzzads} alt="fuzzads" />
            <h2>
              <span style={{ color: "#26017b" }}>Fuzz</span>
              <span style={{ color: "#00a5e0" }}>ADs</span>
            </h2>*/}
          </div>

          <h3>Your Cart</h3>
          <div className="chooseplan-content">
            <div className="chooseplan-left">
              <div className="plan-name">
                <select
                  id="plan-select"
                  value={selectedPlan}
                  onChange={handlePlanChange}
                >
                  <option value="silver">Silver Streak</option>
                  <option value="gold">Golden Glow</option>
                  <option value="platinum">Platinum Power</option>
                </select>
              </div>

              <div className="line"></div>
              <div className="fullplan-detail">
                <div className="planleft-container">
                  <div className="plan-details">
                    <label htmlFor="duration-select">Plan Period</label>
                    <select
                      id="duration-select"
                      value={selectedDuration}
                      onChange={handleDurationChange}
                    >
                      <option value="1 Month">1 Month</option>
                      <option value="1 Year">1 Year</option>
                      <option value="2 Years">2 Years</option>
                    </select>
                    <p>Renew at {currentPlanData.salePrice}</p>
                  </div>
                </div>

                <div className="planright-container">
                  <div className="discount-container">
                    <h4>
                      {currentPlanData.discount}% off - Rs.{" "}
                      {priceDifference.toLocaleString()}
                    </h4>
                    <h5>Discount !!!</h5>
                  </div>
                </div>
              </div>

              <div className="choosebottom-strip">
                WOW! you get a FREE Domain name for 1 year.
              </div>
            </div>
            <div className="chooseplan-right">
              <div className="subtotal">Subtotal</div>
              <div className="price-box">
                <p className="cancelled-discount">
                  {currentPlanData.salePrice}
                </p>
                <p className="actual-price"> ~ {currentPlanData.finalPrice}</p>
              </div>
              <div className="price-box">
                <p className="discount-tag">
                  Plan discount -{currentPlanData.discount}%
                </p>
                <p className="discount-price">
                  {" "}
                  ~ Rs. {priceDifference.toLocaleString()}
                </p>
              </div>
              <div className="checkout-content">
                <button className="checkout-btn">Check Out</button>
                <p className="note">24 hrs money-back guarantee*</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePlan;
