import React from "react";
import applause from "../assets/icons/applause.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../styles/thankyouStyle.css";

const Thankyou = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <>
      <div className="thank-container">
        <div className="thank-content">
          <img src={applause} alt="applause" />
          <h1 style={{color: '#26017b', fontSize: '3rem'}}>Thank You for joining Us !</h1>
          <h4 style={{color: '#00A5E0', fontSize: '2rem'}}>
            We’re excited to collaborate and will get back to you shortly to
            turn your ideas into reality.
          </h4>
          <button onClick={() => navigate("/")}>Back to Home</button>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
