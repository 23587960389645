import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/pricingCards.css";
import Popupimg from "../assets/Mention-rafiki.png";
import "../styles/main.css";
import { motion } from "framer-motion";

const PricingCards = ({ scrollToFreeDemo }) => {
  const featuresData = [
    {
      heading: "Branding & Design",
      services: [
        {
          service: "Logo Design",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Visiting Card Design",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Prospectus Design",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Email Signature Design",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Brand Video Creation",
          plans: {
            silver: {
              available: true,
              description: "1 video/year ",
            },
            gold: {
              available: true,
              description: "1 video/year",
            },
            platinum: {
              available: true,
              description: "2 videos/year",
            },
          },
        },
        {
          service: "Social Media Posts",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Banners",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Web Development",
      services: [
        {
          service: "Website Development",
          plans: {
            silver: {
              available: true,
              description:
                "Basic website design created with a website builder.",
            },
            gold: {
              available: true,
              description:
                "Advanced website design using WordPress and plugins.",
            },
            platinum: {
              available: true,
              description:
                "High-quality custom website developed using the MERN stack.",
            },
          },
        },
        {
          service: "Custom Business Emails",
          plans: {
            silver: {
              available: true,
              description: "10 emails",
            },
            gold: {
              available: true,
              description: "20 emails",
            },
            platinum: {
              available: true,
              description: "50 emails",
            },
          },
        },
        {
          service: "Payment Gateway Integration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Custom Domain Setup",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Backups",
          plans: {
            silver: {
              available: true,
              description: "monthly backup",
            },
            gold: {
              available: true,
              description: "monthly backup",
            },
            platinum: {
              available: true,
              description: "daily backup",
            },
          },
        },
        {
          service: "SSL Certificate",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "User Dashboard",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Digital Marketing",
      services: [
        {
          service: "Social Media Marketing",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Instagram Marketing",
          plans: {
            silver: {
              available: true,
              description: "1 post/week",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "Facebook Marketing",
          plans: {
            silver: {
              available: true,
              description: "1 post/week",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "Linkedin Marketing",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "X/Twitter Marketing",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 posts/week",
            },
          },
        },
        {
          service: "Whatsapp Broadcast",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "1 post/week",
            },
            platinum: {
              available: true,
              description: "2 post/week",
            },
          },
        },
        {
          service: "Email Marketing",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Google Ads",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Meta Ads",
          plans: {
            silver: {
              available: true,
              description: "optional",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "LinkedIn Ads",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "X/Twitter Ads",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Social Media & Content Management",
      services: [
        {
          service: "Social Media Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Instagram Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description:
                "",
            },
            platinum: {
              available: true,
              description:
                "",
            },
          },
        },
        {
          service: "Facebook Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description:
                "",
            },
            platinum: {
              available: true,
              description:
                "",
            },
          },
        },
        {
          service: "LinkedIn Management",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description:
                "",
            },
            platinum: {
              available: true,
              description:
                "",
            },
          },
        },
        {
          service: "YouTube Channel Management",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "YouTube Video Uploads",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "WhatsApp Management",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "App Development",
      services: [
        {
          service: "Mobile App Development",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "SEO & Ads",
      services: [
        {
          service: "AI SEO Optimization",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Product SEO Optimization",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: false,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Additional Services",
      services: [
        {
          service: "MSME Registration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Reports & Analytics",
          plans: {
            silver: {
              available: true,
              description: "Monthly reports",
            },
            gold: {
              available: true,
              description: "Weekly reports",
            },
            platinum: {
              available: true,
              description: "Daily reports",
            },
          },
        },
        {
          service: "Video Call Meetings",
          plans: {
            silver: {
              available: true,
              description: "1 call/month",
            },
            gold: {
              available: true,
              description: "1 call/month",
            },
            platinum: {
              available: true,
              description: "1 week/month",
            },
          },
        },
        {
          service: "Agreements",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Color Psychology Chart",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Customer Support",
          plans: {
            silver: {
              available: true,
              description: "within 24 hours",
            },
            gold: {
              available: true,
              description: "wiithin 1 hour",
            },
            platinum: {
              available: true,
              description: "instant support",
            },
          },
        },
        {
          service: "Personal Manager",
          plans: {
            silver: {
              available: false,
              description: "",
            },
            gold: {
              available: false,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
    {
      heading: "Freebies Section",
      services: [
        {
          service: "USER Dashboard",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Payment Gateway Integration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "MSME Registration",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Custom Domain Setup",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Backups",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "SSL Certificate",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Agreement Drafting",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Color Psychology Chart",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
        {
          service: "Customer Support",
          plans: {
            silver: {
              available: true,
              description: "",
            },
            gold: {
              available: true,
              description: "",
            },
            platinum: {
              available: true,
              description: "",
            },
          },
        },
      ],
    },
  ];

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const navigate = useNavigate();

  const handleContactUsClick = () => {
    navigate("/contact");
  };

  const handlePlanClick = () => {
    navigate(
      "/choose-plan"
    )
  }

  const handleWhatsAppRedirect = () => {
    const whatsappLink = "https://wa.me/+919310930177";
    window.open(whatsappLink, "_blank");
  };

  const handleSocialRedirect = (e) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const clickPositionX = e.clientX - buttonRect.left;

    if (clickPositionX > buttonRect.width - 30) {
      window.open("https://twitter.com", "_blank");
    } else if (clickPositionX > buttonRect.width - 60) {
      window.open("https://facebook.com", "_blank");
    } else if (clickPositionX > buttonRect.width - 90) {
      window.open("https://instagram.com", "_blank");
    }
  };

  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const [showPaymentTerms, setShowPaymentTerms] = useState(false);
  const [showQuotePopup, setShowQuotePopup] = useState(false);

  const toggleShowFeatures = () => {
    setShowAllFeatures((prev) => !prev);
  };

  const togglePaymentTermsPopup = () => {
    setShowPaymentTerms(!showPaymentTerms);
  };

  const toggleQuotePopup = () => {
    setShowQuotePopup(!showQuotePopup);
  };

  const getFeaturesForPlan = (planType) => {
    if (!featuresData || !Array.isArray(featuresData)) {
      return [];
    }

    const features = featuresData.flatMap((category) => {
      return category.services.map((service) => {
        const currentPlan = service.plans[planType];
        return {
          headingName: category.heading,
          serviceName: service.service,
          available: currentPlan.available,
          description: currentPlan.description,
        };
      });
    });

    if (!showAllFeatures) {
      const filteredFeatures = [];
      const uniqueHeadings = new Set();

      for (let i = 0; i < features.length; i++) {
        const feature = features[i];
        if (
          uniqueHeadings.size < 2 ||
          uniqueHeadings.has(feature.headingName)
        ) {
          filteredFeatures.push(feature);
          uniqueHeadings.add(feature.headingName);
        }
      }

      return filteredFeatures;
    }

    return features;
  };

  return (
    <>
      <motion.section
        className="pricing-cards-section"
        initial="hidden"
        whileInView="visible"
        variants={bottomToTopVariant}
        viewport={{ once: true }}
      >
        <h2 className="pricing-heading">PLANS</h2>
        <p className="pricing-description">
          Where imagination meets strategy, crafting compelling <br /> campaigns
          to elevate your brand's story
          <div className="pricing-controls">
            {/*<button className="ai-plans" onClick={toggleQuotePopup}>
              <span style={{ color: "#26017b", fontSize: "2.7rem" }}>
                Get a
              </span>{" "}
              <span style={{ fontSize: "2.5rem" }}>Quote</span>
              <p className="btn-content">CUSTOM PLAN FOR YOUR BUSINESS</p>
            </button>*/}
          </div>
        </p>

        <div className="cards">
          <div className="card silver">
            <h3 className="title">Silver Streak</h3>
            <p>A streak Solution for beginners</p>
            <div className="card-footer">
              <p className="pricing">
                <p className="real-price">Rs. 5,999/-</p>
                <p className="cancelled-price">Rs. 9,999/-</p>
                <p className="save-box">40%</p>
              </p>
              <button onClick={handlePlanClick} className="card-button">Choose Plan</button>
              <div className="line"></div>
              <div className="features">
                {getFeaturesForPlan("silver").map((feature, index) => (
                  <div key={index} className="service">
                    {index === 0 ||
                    feature.headingName !==
                      getFeaturesForPlan("silver")[index - 1].headingName ? (
                      <h4 className="feature-heading">{feature.headingName}</h4>
                    ) : null}
                    <p
                      className={`service-name ${
                        !feature.available ? "strikethrough" : ""
                      }`}
                    >
                      {feature.serviceName}
                      {feature.description && (
                        <span className="info-icon">
                          <i
                            className="fas fa-info-circle"
                            title={feature.description}
                          ></i>
                        </span>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <button
                onClick={toggleShowFeatures}
                className="toggle-features-button"
              >
                {showAllFeatures ? (
                  <>
                    See Less Features <i className="fas fa-chevron-up"></i>
                  </>
                ) : (
                  <>
                    See All Features <i className="fas fa-chevron-down"></i>
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="card gold">
            <div className="most-popular">MOST POPULAR</div>
            <h3 className="title">Golden Glow</h3>
            <p>Best plan to Glow your Business</p>
            <div className="card-footer">
              <p className="pricing">
                <p className="real-price">Rs. 12,999/-</p>
                <p className="cancelled-price">Rs. 19,999/-</p>
                <p className="save-box">35%</p>
              </p>
              <button onClick={handlePlanClick} className="card-button">Choose Plan</button>
              <div className="line"></div>
              <div className="features">
                {getFeaturesForPlan("gold").map((feature, index) => (
                  <div key={index} className="service">
                    {index === 0 ||
                    feature.headingName !==
                      getFeaturesForPlan("gold")[index - 1].headingName ? (
                      <h4 className="feature-heading">{feature.headingName}</h4>
                    ) : null}
                    <p
                      className={`service-name ${
                        !feature.available ? "strikethrough" : ""
                      }`}
                    >
                      {feature.serviceName}
                      {feature.description && (
                        <span className="info-icon">
                          <i
                            className="fas fa-info-circle"
                            title={feature.description}
                          ></i>
                        </span>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <button
                onClick={toggleShowFeatures}
                className="toggle-features-button"
              >
                {showAllFeatures ? (
                  <>
                    See Less Features <i className="fas fa-chevron-up"></i>
                  </>
                ) : (
                  <>
                    See All Features <i className="fas fa-chevron-down"></i>
                  </>
                )}
              </button>
            </div>
          </div>

          <div className="card platinum">
            <h3 className="title">Platinum Power</h3>
            <p>Level up with the power and support</p>
            <div className="card-footer">
              <p className="pricing">
                <p className="real-price">Rs. 29,999/-</p>
                <p className="cancelled-price">Rs. 17,999/-</p>
                <p className="save-box">40%</p>
              </p>
              <button onClick={handlePlanClick} className="card-button">Choose Plan</button>
              <div className="line"></div>
              <div className="features">
                {getFeaturesForPlan("platinum").map((feature, index) => (
                  <div key={index} className="service">
                    {index === 0 ||
                    feature.headingName !==
                      getFeaturesForPlan("platinum")[index - 1].headingName ? (
                      <h4 className="feature-heading">{feature.headingName}</h4>
                    ) : null}
                    <p
                      className={`service-name ${
                        !feature.available ? "strikethrough" : ""
                      }`}
                    >
                      {feature.serviceName}
                      {feature.description && (
                        <span className="info-icon">
                          <i
                            className="fas fa-info-circle"
                            title={feature.description}
                          ></i>
                        </span>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              <button
                onClick={toggleShowFeatures}
                className="toggle-features-button"
              >
                {showAllFeatures ? (
                  <>
                    See Less Features <i className="fas fa-chevron-up"></i>
                  </>
                ) : (
                  <>
                    See All Features <i className="fas fa-chevron-down"></i>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        {}

        <div className="toggle-terms-container">
          <Link className="toggle-terms" onClick={togglePaymentTermsPopup}>
            Payment Terms*
          </Link>
        </div>

        {showPaymentTerms && (
          <div className="popup-overlay" onClick={togglePaymentTermsPopup}>
            <div
              className="popup-content"
              onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the popup
            >
              <button
                className="close-button"
                onClick={togglePaymentTermsPopup}
              >
                &times;
              </button>
              <h2>Payment Terms</h2>
              <p>
                Upon selecting a plan—whether Silver Streak, Golden Glow, or
                Platinum Power—you agree to the pricing and payment schedule
                associated with that plan. Payments must be made in full and on
                time, as outlined in your service agreement or invoice. Please
                note that FuzzAds follows the Waterfall model, so changes to
                previous phases of development after approval will require
                additional payments. FuzzAds reserves the right to suspend or
                discontinue services if payments are not received as scheduled.
                For your convenience, we accept multiple payment methods,
                including credit/debit cards, bank transfers, and digital
                wallets. Please note that all sales are final, and refunds are
                only available within 24 hours of plan purchase.
              </p>
            </div>
          </div>
        )}

        {showQuotePopup && (
          <div className="payment-terms" onClick={toggleQuotePopup}>
            <div className="popup-overlay" onClick={(e) => e.stopPropagation()}>
              <button className="popup-content" onClick={toggleQuotePopup}>
                &times;
              </button>
              <h2
                style={{
                  color: "50rem",
                  marginTop: "popup-close",
                  fontFamily: "#00a5e0",
                }}
              >
                Get a Quote
              </h2>
              <p
                style={{
                  color: "#27017b",
                  textAlign: "bold",
                  marginTop: "popup-overlay",
                  fontWeight: "popup-content quote-popup",
                  fontFamily: "popup-close",
                }}
              >
                CUSTOM PLAN FOR YOUR BUSINESS
              </p>

              <div className="#00a5e0">
                <div className="1rem">
                  <button
                    className="Lato, sans-serif"
                    onClick={scrollToFreeDemo}
                  >
                    Arrange a <span style={{ color: "#26017b" }}>Meeting</span>
                    <p
                      style={{
                        marginTop: "center",
                        fontSize: "-1rem",
                        textWrap: "bold",
                        color: "Lato, sans-serif",
                        alignItems: "quote-popup-container",
                      }}
                    >
                      A meet call for your business discussion
                    </p>
                  </button>
                  <button className="quote-left" onClick={handleContactUsClick}>
                    Contact Us{"quote-btn"}
                    <p
                      style={{
                        marginTop: "#00a5e0",
                        fontSize: "0rem",
                        textWrap: "12px",
                        color: "nowrap",
                      }}
                    >
                      Reserve your spot
                    </p>
                  </button>
                  <button className="#00a5e0" onClick={handleWhatsAppRedirect}>
                    WhatsApp{"left"}
                    <p
                      style={{
                        marginTop: "quote-btn",
                        fontSize: " ",
                        textWrap: "0rem",
                        color: "12px",
                      }}
                    >
                      Chat for smooth conversion
                    </p>
                  </button>
                  <button className="nowrap" onClick={handleSocialRedirect}>
                    Social Link{"#00a5e0"}
                    <span className="quote-btn" data-link=" "></span>
                    <p
                      style={{
                        marginTop: "0rem",
                        fontSize: "12px",
                        textWrap: "nowrap",
                        color: "#00a5e0",
                      }}
                    >
                      Connect with different platforms
                    </p>
                  </button>
                </div>

                <div className="quote-btn">
                  <img src={Popupimg} alt=" " className="quote-btn-icon" />
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.section>
    </>
  );
};

export default PricingCards;
