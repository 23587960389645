// Homepage.js

import React, { useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/hero";
import PricingCards from "../components/PricingCards";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import "../styles/main.css";
import WhatsAppIcon from "../components/WhatsappIcon";
import Advertisement from "../components/Advertisement";
import Banner from "../components/Banner";
import Connect from "../components/Connect";
import Freedemo from "../components/FreedemoComponent";
import { useLocation } from "react-router-dom"; 
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "../components/ScrolltoTop";

const Homepage = () => {
  const pricingRef = useRef(null);
  const freedemoRef = useRef(null); 
  const location = useLocation(); 

  const scrollToPricing = () => {
    pricingRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToFreeDemo = () => {
    if (freedemoRef.current) {
      freedemoRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (location.state && location.state.scrollToDemo) {
      freedemoRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (location.state && location.state.scrollToPricing) {
      pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]); 

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>
    <ScrollToTop />
      <header className="navbar">
        <Navbar scrollToPricing={scrollToPricing} scrollToFreeDemo={scrollToFreeDemo} />
      </header>

      <div className="content-container">
        <section className="hero">
          <Hero scrollToPricing={scrollToPricing} />
          <div ref={pricingRef}>
            <PricingCards scrollToFreeDemo={scrollToFreeDemo} />
          </div>
          <div ref={freedemoRef}>
            <Freedemo />  
          </div>
          <Banner scrollToPricing={scrollToPricing} />
          <Connect scrollToPricing={scrollToPricing} />
        </section>
      </div>

      <footer className="footer">
        <Footer />
      </footer>
      <WhatsAppIcon />
    </>
  );
};

export default Homepage;
