import React from "react";
import "../styles/errorpageStyles.css"; // Assuming you saved the CSS in ErrorPage.css
import { Link } from "react-router-dom"; // To handle navigation

const Error = () => {
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <div className="four_zero_four_bg">
              </div>
              <div className="contant_box_404">
                <h3 className="h2" style={{fontFamily: 'lato, sans-serif', fontSize: '3rem', color: '#26017b'}}>Look like you're lost</h3>
                <p style={{fontFamily: 'lato, sans-serif', fontSize: '2rem', color: '#26017b'}}>The page you are looking for is not available!</p>
                <Link to="/" className="link_404">Go to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
