import React from "react";
import "../styles/termscondition.css";

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <div className="terms-content">
        <h1>Terms and Conditions</h1>
        <p>Last Updated on September 1, 2024 </p>
        <p>
          <h2>AGREEMENT TO OUR LEGAL TERMS </h2>
          <p>
            Welcome to FuzzAds. These Terms and Conditions govern your use of
            our services, including social media marketing management, website
            building, logo design, and other related services. By accessing or
            using our services, you agree to comply with and be bound by these
            terms.
          </p>
          <h3>1. Services </h3>
          FuzzAds provides various digital marketing and development services
          under three plans: Silver, Gold, and Platinum. The scope of services
          is defined in the service agreement provided to the client. Additional
          services, such as software solutions and SEO, are also available.
        </p>
        <p>
          <h3>2. Payment Terms </h3>
          <p>
            Payments for services must be made according to the terms specified
            in the service agreement. FuzzAds reserves the right to withhold or
            suspend services in case of delayed or non-payment.
          </p>
          <h4>
            Note:- Payment updation could take 3 to 5 days on failure or pending
            transactions done through the respective mode of transactions{" "}
          </h4>
          <h3>3. Intellectual Property </h3>
          All content, designs, and materials created by FuzzAds remain the
          property of FuzzAds until full payment is received. Clients are
          granted a non-exclusive, non-transferable license to use the
          deliverables as specified in the agreement.
          <h3>4. Confidentiality </h3>
          <p>
            FuzzAds and the client agree to keep all confidential information
            private and not disclose it to any third party, except as required
            by law.
          </p>
          <h3>5. Limitation of Liability </h3>
          <p>
            FuzzAds will not be liable for any indirect, incidental, or
            consequential damages arising from the use of our services. Our
            liability is limited to the amount paid by the client for the
            specific service in question.
          </p>
          <h3>6. Termination </h3>
          <p>
            Either party may terminate the agreement with written notice. Any
            outstanding payments for services rendered must be settled upon
            termination
          </p>
          <h3>7. Governing Law </h3>
          <p>
            These terms and conditions are governed by the laws of Indian
            Jurisdiction. Any disputes arising from these terms will be subject
            to the exclusive jurisdiction of the courts in india Jurisdiction.
          </p>
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
