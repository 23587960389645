import React from "react";
import "../styles/bannerStyles.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Banner = ({ scrollToPricing }) => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      // Scroll to PricingCards if logged in
      scrollToPricing();
    } else {
      // Navigate to the Login page if not logged in
      navigate("/login");
    }
  };
  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 },  // Start hidden below the original position
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },  // Fade in to the original position
  };
  

  return (
    <div className="banner-container">
      <motion.div
        className="banner-content"
        style={{ fontFamily: "Lato, sans-serif", textAlign: "center" }}
        initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}
      >
        <p
          style={{
            color: "white",
            fontFamily: "Lato, sans-serif",
            fontSize: "40px",
            textAlign: "center",
          }}
        >
          With free Hosting for 3 Months + 1 Month <br /> Free Plan{" "}
        </p>
        <p style={{ fontSize: "20px", color: "white", marginTop: '-1.5rem' }}>
          {" "}
          "Empowering Brands with Digital Brilliance"
        </p>
        <button
          className="get-startedbtn"
          style={{
            fontSize: "25px",
            padding: "9px",
            borderRadius: '50px',
            fontWeight: "bold"
          }}
          onClick={handleGetStartedClick}
        >
          Get Started
        </button>
      </motion.div>
    </div>
  );
};

export default Banner;
