import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FaBars, FaTimes } from "react-icons/fa";
import loader from "../assets/fuzzads-loader.gif";
import "../styles/navbar.css";

const Navbar = ({ scrollToPricing }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [userInitials, setUserInitials] = useState(null);
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [isNavigating, setIsNavigating] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => setClick(!click);
  const togglePopup = () => setIsOpen(!isOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = {
      name: event.target.name.value,
      location: event.target.location.value,
      phone: phone,
    };

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        event.target.reset();
        setPhone("");
        setIsOpen(false);
      } else {
        console.error("Error:");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInitials");
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    const initials = localStorage.getItem("userInitials");
    if (initials) {
      setUserInitials(initials);
    }

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  // Line loader effect when navigating
  useEffect(() => {
    let timeoutId;
    setIsNavigating(true); // Set navigating state to true at start

    timeoutId = setTimeout(() => {
      setIsNavigating(false); // Set navigating state to false after 1 second
    }, 1000); // Adjust duration as needed

    return () => {
      clearTimeout(timeoutId); // Clean up timeout when component unmounts
    };
  }, [location.pathname]); // Trigger when location changes

  return (
    <>
      <header>
        {isNavigating && <div className="loader-bar"></div>} {/* Loader Bar */}
        <div className="top-strip">
          <div className="contact-left">
            <span className="contact-item">
              <a
                href="mailto:contact@fuzzads.com"
                style={{ color: "white", textDecoration: "none" }}
              >
                <i className="fas fa-envelope"></i> contact@fuzzads.com
              </a>
            </span>
          </div>
          <div className="request-call">
            <span onClick={togglePopup} className="request-call-link">
              Request a Call!
            </span>
          </div>
          <div className="contact-right">
            <span className="contact-item">
              <a
                href="tel:+919310930177"
                style={{ color: "white", textDecoration: "none" }}
              >
                <i className="fas fa-phone"></i> +91 93109-30177
              </a>
            </span>
          </div>
        </div>

        <nav className={color ? "navbar navbar-bg" : "navbar"}>
          <div className="navbar-container">
            <div className="navbar-logo">
              <Link
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={(e) => {
                  scrollToTop();
                  setActiveLink("/");
                }}
              >
                <span className="logo-fuzz">Fuzz</span>
                <span className="logo-ads">Ads</span>
              </Link>
            </div>
            <ul className={click ? "navbar-menu active" : "navbar-menu"}>
              <Link
                to="/"
                className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                onClick={(e) => {
                  scrollToTop();
                  setActiveLink("/");
                }}
              >
                Home
              </Link>
              <Link
                to="/services"
                className={`nav-link ${
                  activeLink === "/services" ? "active" : ""
                }`}
                onClick={(e) => {
                  scrollToTop();
                  setActiveLink("/services");
                }}
              >
                Services
              </Link>
              <Link
                to="#"
                className={`nav-link ${
                  activeLink === "/plans" ? "active" : ""
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  if (scrollToPricing) {
                    scrollToPricing();
                  }
                }}
              >
                Plans
              </Link>
              <Link
                to="/contact"
                className={`nav-link ${
                  activeLink === "/contact" ? "active" : ""
                }`}
                onClick={(e) => {
                  scrollToTop();
                  setActiveLink("/contact");
                }}
              >
                Contact Us
              </Link>
            </ul>
            <div className="navbar-buttons">
              {userInitials ? (
                <div className="user-icon-container">
                  <span className="user-initials" onClick={toggleDropdown}>
                    {userInitials}
                  </span>
                  {dropdownOpen && (
                    <div className="user-dropdown">
                      <span className="dropdown-item" onClick={handleLogout}>
                        Logout
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <Link to="/login" className="login-button" onClick={scrollToTop}>
                  Get Started
                </Link>
              )}
            </div>
            <div className="hamburger" onClick={handleClick}>
              {click ? (
                <FaTimes size={20} style={{ color: "#26017B" }} />
              ) : (
                <FaBars size={20} style={{ color: "#26017B" }} />
              )}
            </div>
          </div>
        </nav>

        {isOpen && (
          <div className="popup-overlay">
            <div className="popup-content">
              <button className="close-button" onClick={togglePopup}>
                ×
              </button>
              <h2 className="popup-heading">Request a Call</h2>
              <form className="call-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Your Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="location">Location:</label>
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="Enter Your Location"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number:</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                    defaultCountry="IN"
                    id="phone"
                    name="phone"
                    required
                  />
                </div>
                <button type="submit" className="submit-link">
                  Request a Call Back
                </button>
              </form>
            </div>
          </div>
        )}

        {loading && (
          <div className="loader-overlay">
            <div className="loader">
              <img src={loader} alt="loader" />
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Navbar;
