import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/connectStyles.css";
import connect from "../assets/connect.png";
import Popupimg from "../assets/Self-confidence-bro.png";
import "../styles/pricingCards.css";
import { motion } from "framer-motion";

const Connect = ({ scrollToPricing, scrollToFreeDemo }) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 }, // Start hidden below the original position
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }, // Fade in to the original position
  };

  const freeDemoRef = useRef(null);
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const toggleQuotePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleConnectButtonClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleContactUsClick = () => {
    navigate("/contact");
  };

  const handleWhatsAppRedirect = () => {
    const whatsappLink = "https://wa.me/+919310930177";
    window.open(whatsappLink, "_blank");
  };

  const handleSocialRedirect = (e) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const clickPositionX = e.clientX - buttonRect.left;

    if (clickPositionX > buttonRect.width - 30) {
      window.open("https://twitter.com", "_blank");
    } else if (clickPositionX > buttonRect.width - 60) {
      window.open("https://facebook.com", "_blank");
    } else if (clickPositionX > buttonRect.width - 90) {
      window.open("https://instagram.com", "_blank");
    }
  };

  const handleGetStartedClick = () => {
    const token = localStorage.getItem("token");

    if (token) {
      scrollToPricing();
    } else {
      navigate("/create-account");
    }
  };

  return (
    <>
      <div className="connect-container">
        <div className="connect-content">
          <motion.div
            className="connect-left"
            initial="hidden"
            whileInView="visible"
            variants={leftToRightVariant}
            viewport={{ once: true}}
          >
            <p
              style={{
                fontFamily: "Lato, sans-serif",
                color: "#26017b",
                fontSize: "40px",
                fontWeight: "bold",
              }}
            >
              Here for{" "}
              <span style={{ color: "#00a5e0" }}>You Around the Clock</span>
            </p>
            <ul className="connect-list">
              <li>Get expert assistance anytime via live chat and email.</li>
              <li>
                Our team speaks 10+ languages, ensuring seamless communication.
              </li>
              <li>
                Experience swift support with typical response times under 2
                minutes.
              </li>
            </ul>
            <button
              onClick={handleConnectButtonClick}
              style={{
                padding: "10px",
                borderRadius: "100px",
                border: "2px solid #00a5e0",
                fontWeight: "bold",
                marginTop: "2rem",
              }}
            >
              Connect Us 24/7
            </button>
          </motion.div>
          <motion.div
            className="connect-right"
            initial="hidden"
            whileInView="visible"
            variants={rightToLeftVariant}
            viewport={{ once: true}}
          >
            <img src={connect} alt="connect-us" />
          </motion.div>
        </div>
      </div>

      <motion.div
        className="choose-us-container"
        initial="hidden"
        whileInView="visible"
        variants={bottomToTopVariant}
        viewport={{ once: true}}
      >
        <h1>
          WHY <span>CHOOSE US</span> ?
        </h1>
        <h2>
          Dedicated to your <span>Brand's Success</span>
        </h2>
        <div className="choose-us">
          <p>
            At FuzzAds, we don’t just provide services—we partner with you to
            bring your brand’s vision to life. Our approach is personal,
            innovative, and tailored to your unique needs. Whether you’re a
            small business just starting out or a company ready to scale, we
            offer flexible solutions that grow with you. What sets us apart is
            our passion for creativity and commitment to delivering results that
            matter.
          </p>
          <p>
            We believe in transparency, communication, and going the extra mile.
            You’ll always know what we’re working on, and we’ll make sure every
            step of the process aligns with your goals. With fresh ideas, a
            focus on detail, and the latest tools, FuzzAds is here to build not
            just your digital presence, but a partnership you can rely on.
          </p>
          <p>
            Choose FuzzAds because your success is our mission, and we’ll make
            sure to take that journey with you every step of the way.
          </p>
          <button onClick={handleGetStartedClick}>Get Started</button>
        </div>
      </motion.div>

      {showPopup && (
        <div className="popup-overlay" onClick={toggleQuotePopup}>
          <div
            className="popup-content quote-popup"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="popup-close" onClick={toggleQuotePopup}>
              &times;
            </button>
            <h2
              style={{
                color: "#00a5e0",
                marginTop: "1rem",
                fontFamily: "Lato, sans-serif",
              }}
            >
              Connect Us
            </h2>
            <p
              style={{
                color: "#26017b",
                textAlign: "center",
                marginTop: "-1rem",
                fontWeight: "bold",
                fontFamily: "Lato, sans-serif",
              }}
            >
              WE ARE READY TO HELP YOU 24/7
            </p>

            <div className="quote-popup-container">
              <div className="quote-left">
                <button className="quote-btn" onClick={scrollToFreeDemo}>
                  Arrange a <span style={{ color: "#00a5e0" }}>Meeting</span>
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                      alignItems: "left",
                    }}
                  >
                    A meet call for your business discussion
                  </p>
                </button>

                <button className="quote-btn" onClick={handleContactUsClick}>
                  Contact Us{" "}
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                    }}
                  >
                    Reserve your spot
                  </p>
                </button>
                <button className="quote-btn" onClick={handleWhatsAppRedirect}>
                  WhatsApp{" "}
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                    }}
                  >
                    Chat for smooth conversion
                  </p>
                </button>
                <button className="quote-btn" onClick={handleSocialRedirect}>
                  Social Link{" "}
                  <span className="quote-btn-icon" data-link="twitter"></span>
                  <p
                    style={{
                      marginTop: "0rem",
                      fontSize: "12px",
                      textWrap: "nowrap",
                      color: "#00a5e0",
                    }}
                  >
                    Connect with different platforms
                  </p>
                </button>
              </div>

              <div className="quote-right">
                <img
                  src={Popupimg}
                  alt="AI Logo"
                  className="popup-image"
                  style={{ maxWidth: "300px" }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Connect;
