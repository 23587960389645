import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../styles/signup.css";
import SignupBro from "../assets/Signup-bro.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import googgleicon from "../assets/icons/google.png";
import loaderGif from "../assets/fuzzads-loader.gif"; // Import the loader image

const SignupPage = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [loading, setLoading] = useState(false); // State to manage loading
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessName: "",
    email: "",
    mobile: "",
    password: "",
  });

  const { firstName, lastName, businessName, email, mobile, password } =
    formData;

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isTermsChecked) {
      alert("Please agree to the Terms and Conditions before signing up.");
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        firstName,
        lastName,
        businessName,
        email,
        mobile,
        password,
      });

      const res = await axios.post(
        "https://fuzzads-main-server.onrender.com/api/auth/signup",
        body,
        config
      );

      console.log("User registered successfully:", res.data);

      // Store user initials in local storage
      localStorage.setItem(
        "userInitials",
        `${firstName.charAt(0)}${lastName.charAt(0)}`
      );

      // Redirect to homepage
      navigate("/");
    } catch (err) {
      console.error("Error registering user:", err.response.data);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = "http://localhost:5000/api/auth/google"; // Trigger Google OAuth
  };

  return (
    <div className="fullsignup-container">
      {loading && (
        <div className="loader-overlay">
          <img src={loaderGif} alt="Loading..." className="loader-image" />
        </div>
      )}
      <div className="signup-boxs">
        <div className="signupbox-left">
          <h2 className="signupleft-heading">REGISTER</h2>
          <p className="signupleft-subheading">
            Ready to Boost up your Business
          </p>
          <form className="signup-form" onSubmit={onSubmit}>
            <label htmlFor="first-name" className="form-label" >
              Full Name
            </label>
            <div className="name-inputs">
              <input
                type="text"
                id="first-name"
                name="firstName"
                className="first-name"
                placeholder="First Name"
                value={firstName}
                onChange={onChange}
                required
                style={{marginTop: '10px'}}
              />
              <input
                type="text"
                id="last-name"
                name="lastName"
                className="last-name"
                placeholder="Last Name"
                value={lastName}
                onChange={onChange}
                required
                style={{marginTop: '10px'}}
              />
            </div>

            <label htmlFor="business" className="form-label">
              Business Name
            </label>
            <input
              type="text"
              id="business"
              name="businessName"
              className="form-input"
              placeholder="Enter Your Business Name"
              value={businessName}
              onChange={onChange}
              style={{marginTop: '10px'}}
              required
            />
            <label htmlFor="email" className="form-label">
              Email ID
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-input"
              placeholder="Enter Your Email ID"
              value={email}
              onChange={onChange}
              style={{marginTop: '10px'}}
              required
            />

            <label htmlFor="mobile" className="form-label">
              Mobile Number
            </label>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              className="form-input"
              placeholder="Enter Your Mobile Number"
              value={mobile}
              onChange={onChange}
              style={{marginTop: '10px'}}
              required
            />

            <label htmlFor="password" className="form-label">
              Create a Password
            </label>
            <div className="password-container">
              <input
                type={passwordVisible ? "text" : "password"}
                id="password"
                name="password"
                className="form-input"
                placeholder="Enter your Password"
                value={password}
                onChange={onChange}
                style={{marginTop: '10px'}}
                required
              />
              <span
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>

            <button
              type="submit"
              className="signup-button"
              disabled={!isTermsChecked}
            >
              Sign in to <span style={{ color: "#26017b" }}>Fuzz</span>ADs
            </button>
            <p className="or-divider">OR</p>

            <button
              type="button" // Change to button to avoid form submission
              className="google-button"
            >
              <img src={googgleicon} alt="google" /> Continue with Google
            </button>

            <p className="login-text">
              Already have an account on FuzzADs?{" "}
              <a href="/login" className="login-link">
                Login Now
              </a>
            </p>
          </form>
        </div>
        <div className="signupbox-right">
          <div className="signupright-content">
            <h2>
              Fuzz<span style={{ color: "#00a5e0" }}>ADs</span>
            </h2>
            <img src={SignupBro} alt="Signupbro" className="signup-image" />
            <h3>Register Now!!!</h3>
            <div className="terms">
              <input
                type="checkbox"
                id="terms"
                checked={isTermsChecked}
                onChange={(e) => setIsTermsChecked(e.target.checked)}
              />
              <label htmlFor="terms" className="terms-label">
                <a
                  href="/terms-and-conditions"
                  className="terms-link"
                  style={{ textDecoration: "none", color: "#00a5e0" }}
                >
                  Terms & Conditions*
                </a>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
