import React from "react";
import "../styles/services.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import call from "../assets/icons/phone-call.png";

const servicesData = [
  {
    image: require("../assets/services/web-dev.png"),
    heading: "Web Development",
    content:
      "We design beautiful, easy-to-use websites that bring your brand to life and provide a smooth, engaging experience for your visitors.",
    link: "/get-started-1",
  },
  {
    image: require("../assets/services/digital-marketing.jpg"),
    heading: "Digital Marketing",
    content:
      "Increase your brand’s reach with tailored digital marketing strategies that drive traffic and boost engagement.",
    link: "/get-started-2",
  },
  {
    image: require("../assets/services/app-development.png"),
    heading: "App Development",
    content:
      "We design beautiful, easy-to-use websites that bring your brand to life and provide a smooth, engaging experience for your visitors.",
    link: "/get-started-3",
  },
  {
    image: require("../assets/services/graphic-design.png"),
    heading: "Graphic Designing",
    content:
      "Create eye-catching visuals that make your brand stand out and communicate your message effectively.",
    link: "/get-started-4",
  },
  {
    image: require("../assets/services/social-media.png"),
    heading: "Social Media Marketing",
    content:
      "Engage your audience and grow your brand with creative, targeted social media strategies that deliver results.",
    link: "/get-started-6",
  },
  {
    image: require("../assets/services/seo.jpg"),
    heading: "Search Engine Optimization",
    content:
      "Improve your website’s visibility and drive organic traffic with effective SEO strategies tailored to your business.",
    link: "/get-started-4",
  },
  {
    image: require("../assets/services/artificial-intelligence.png"),
    heading: "AI Integration",
    content:
      "Enhance your website with intelligent AI solutions that automate processes, personalize user experiences, and improve overall efficiency.",
    link: "/get-started-5",
  },
  {
    image: require("../assets/services/personal-management.jpg"),
    heading: "Personal Manager",
    content:
      "Get dedicated, one-on-one support with a personal manager who ensures your projects run smoothly and meet your goals efficiently.",
    link: "/get-started-6",
  },
  {
    image: require("../assets/services/email.png"),
    heading: "Free Business Emails",
    content:
      "Receive up to 10 professional business emails with select plans, giving your brand a more credible and polished online presence.",
    link: "/get-started-6",
  },
];

const Service = () => {
  const leftRightVariants = {
    hidden: { x: "-100vw", opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1.5 },
    },
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 }, // Start hidden below the original position
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }, // Fade in to the original position
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const navigate = useNavigate();

  const handleGetStartedClick = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/", { state: { scrollToPricing: true } });
    } else {
      navigate("/create-account");
    }
  };
  const handleButtonClick = () => {
    // Replace '/desired-route' with the route you want to navigate to
    navigate("/contact");
  };

  return (
    <>
      <div className="service-container">
        <div className="service-video">
          <h2>Services</h2>
        </div>
        <div className="services-content">
          <h1>
            WHAT WE <span>OFFER</span> ?
          </h1>
          <h2>
            Expert Solutions for <span>Digital Success</span>
          </h2>
          <motion.div
            className="ser-container"
            initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}
          >
            <div className="services-description">
              <p>
                At Fuzz<span style={{ color: "#00a5e0" }}>Ads</span>, we offer a
                full range of digital services to help your business grow and
                succeed. From expert social media marketing management and
                custom website design to logo creation and branding, we craft
                solutions that make your brand stand out. We also specialize in
                SEO and content marketing, ensuring your online presence
                attracts the right audience. Whether you need professional
                visiting cards, graphic design, tailored software solutions, or
                targeted ad campaigns, Fuzz<span>Ads</span> has the creativity
                and expertise to bring your vision to life. Choose the plan that
                fits your needs, and let us elevate your brand!
              </p>
            </div>
          </motion.div>

          <motion.div
            className="services-grid"
            initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}
          >
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="service-card"
                style={{
                  backgroundColor: index % 2 === 0 ? "#00a5e0" : "#27017b",
                  borderColor: index % 2 === 0 ? "#27017b" : "#00a5e0",
                }}
              >
                <div className="service-card-header">
                  <img src={service.image} alt={service.heading} />
                  <h3>{service.heading}</h3>
                </div>
                <div className="service-card-content">
                  <p>{service.content}</p>
                </div>
                <a
                  href="#"
                  onClick={handleGetStartedClick}
                  className="get-started-link"
                >
                  Get Started &gt;&gt;
                </a>
              </div>
            ))}
          </motion.div>
        </div>
        <div
          className="servicebanner-container"
          
        >
          <motion.div className="servicebanner-content" initial="hidden"
          whileInView="visible"
          variants={bottomToTopVariant}
          viewport={{ once: true }}>
            <h1>With free Hosting for 3 Months + 1 Month Free Plan</h1>
            <h2>"Empowering Brands with Digital Brilliance"</h2>
            <button
              className="serviceget-startedbtn"
              onClick={handleGetStartedClick}
            >
              Get Started
            </button>
          </motion.div>
        </div>
        <motion.div className="add-services" initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}>
          <button onClick={handleButtonClick}>Contact Us</button>
          <a href="tel:+919310930177" style={{ textDecoration: "none" }}>
            <div className="call-container">
              <img src={call} alt="profile" className="call-img" />
              <div className="call-text">
                <h5>
                  <span>+91-9310930177</span> <br />
                  <span style={{ color: "#00a5e0" }}>Talk to our expert</span>
                </h5>
              </div>
            </div>
          </a>
        </motion.div>
      </div>
    </>
  );
};

export default Service;
