import React from "react";
import "../styles/footer.css";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaMailchimp,
  FaMailBulk,
  FaEnvelope,
} from "react-icons/fa";
import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import twitter from "../assets/icons/twitter.png";
import youtube from "../assets/icons/youtube.png";
import linkedin from "../assets/icons/linkedin.png";
import centerImage from "../assets/Online-rafiki 1.png";
import "../styles/main.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const handleMailClick = () => {
    window.location.href = "mailto:support@fuzzads.com";
  };

  return (
    <footer className="footer">
      <div className="footer-circle"></div>
      <div className="footer-circle-small"></div>
      <div className="circle-content">
        <span className="logo-fuzz">Fuzz</span>
        <span className="logo-ads">ADs</span>
      </div>
      <div className="footer-tagline">
        <p>Your Brand Our team</p>
      </div>
      <div className="mail-box">
        <button
          className="mail-btn"
          onClick={handleMailClick}
          style={{ display: "flex", alignItems: "center", color: '#ffffff' }}
        >
          <FaEnvelope style={{ marginRight: "8px", color: "#ffffff", paddingLeft: '5px' }} /> support@fuzzads.com
        </button>
      </div>
      <div className="copyright">
        <p>&copy; {currentYear} Fuzz Ads. All rights reserved.</p>
      </div>
      <div className="footer-icons">
        <div className="footer-icon fb">
          <a>
          <img
            src={facebook}
            alt="facebook"
            style={{ width: "40px", height: "auto" }} className="social"
          />
          </a>
        </div>
        <div className="footer-icon tw">
          <a>
          <img
            src={instagram}
            alt="instagram"
            style={{ width: "50px", height: "auto" }}  className="social"
          />
          </a>
        </div>
        <div className="footer-icon ig">
          <a>
          <img
            src={twitter}
            alt="twitter"
            style={{ width: "40px", height: "auto" }} className="social"
          />
          </a>
        </div>
        <div className="footer-icon yt">
          <a >
          <img
            src={youtube}
            alt="youtube"
            style={{ width: "40px", height: "auto" }} className="social"
          />
          </a>
        </div>
        <div className="footer-icon lk">
          <a>
          <img
            src={linkedin}
            alt="linkedin"
            style={{ width: "40px", height: "auto" }} className="social"
          />
          </a>
        </div>
      </div>
      <div className="footer-center">
        <img src={centerImage} alt="Center" className="center-image" />
      </div>
      <div className="footer-circle-bottom-right"></div>{" "}
      {/* New circle at the bottom right */}
      <div className="footer-links">
        <div className="footer-links-column-one">
          <ul>
            <li>
              <a href="/services">Services</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
            <li>
              <a href="/privacy-policy">
                Privacy <br />
                Policy
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-links-column-two">
          <ul>
            <li>
              <a href="/about">About</a>
            </li>
            <li>
              <a href="/terms-and-conditions">
                Terms & <br />
                Conditions
              </a>
            </li>
            <li>
              <a href="/return-and-refund-policy">
                Return & <br />
                Refund
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
