import React from "react";
import "../styles/privacy.css";

const Privacypolicy = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>PRIVACY NOTICE</h1>
        <p>
          <h3>1. Introduction</h3>
          <p>
            FuzzAds is committed to protecting your privacy. This Privacy Policy
            explains how we collect, use, and share your personal information
            when you use our services.
          </p>
        </p>

        <p>
          <h3>2. Information We Collect </h3>
          We may collect personal information such as your name, email address,
          phone number, and payment details. We also collect information about
          your business, including website data and social media accounts, to
          provide our services. Additionally, we collect data through
          authentication methods, including login and sign-ups, to secure your
          account and personalize your experience.
        </p>

        <p>
          <h3>3. How We Use Your Information </h3>
          <p>
            We use your information to deliver our services, process payments,
            communicate with you, and improve our offerings. We may also use
            your data for marketing purposes, with your consent.
          </p>
        </p>
        <p>
          <h3>4. Sharing Your Information </h3>
          <p>
            We do not share your personal information with third parties except
            as necessary to provide our services or as required by law. We may
            share your information with trusted partners who assist us in
            delivering our services.
          </p>{" "}
        </p>
        <p>
          <h3>5. Data Security </h3>
          <p>
            We implement appropriate security measures to protect your data from
            unauthorized access, disclosure, or misuse. However, no method of
            transmission over the internet is completely secure.
          </p>
        </p>
        <p>
          <h3>6. Your Rights </h3>
          <p>
            You have the right to access, update, or delete your personal
            information at any time. You may also opt out of receiving marketing
            communications from us.
          </p>
        </p>
        <p>
          <h3>7. Changes to This Policy </h3>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on our website with the revised date
          </p>
        </p>
        <p>
          <h3>8. Contact Us </h3>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at fuzzads.co@gmail.com or +91 9310930177.
          </p>
        </p>
        
      </div>
    </div>
  );
};

export default Privacypolicy;
