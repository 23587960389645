import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "../styles/freedemoStyles.css";
import catalogue from "../assets/free-demo.png";
import loader from "../assets/fuzzads-loader.gif";
import { motion } from "framer-motion";

const Freedemo = forwardRef((props, ref) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const [phone, setPhone] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    dateTime: new Date(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      phone: phone,
    });
  };

  const handleDateTimeChange = (date) => {
    setFormData({
      ...formData,
      dateTime: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/demoform",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
            location: formData.companyName,
            timeslot: formData.dateTime.toString(),
          }),
        }
      );

      if (response.ok) {
        setSuccessMessage("We will connect you soon!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          dateTime: new Date(),
        });
      } else {
        setSuccessMessage("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      setSuccessMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      setTimeout(() => setSuccessMessage(""), 3000);
    }
  };

  return (
    <section ref={ref} className="catalogue-container">
      <div className="catalogue-content">
        <h2 className="catalogue-heading">Discover What We Offer</h2>
        <h3 className="catalogue-subheading">Schedule Your Free Demo</h3>
        <div className="catalogue-inner">
          <motion.div
            className="left-section"
            initial="hidden"
            whileInView="visible"
            variants={leftToRightVariant}
            viewport={{ once: true}}
          >
            <img className="catalogue-image" src={catalogue} alt="catalogue" />
          </motion.div>
          <motion.div
            className="right-section"
            initial="hidden"
            whileInView="visible"
            variants={rightToLeftVariant}
            viewport={{ once: true}}
          >
            {isLoading && (
              <div className="loader-overlay">
                <div className="loader">
                  <img src={loader} alt="loader" />
                </div>
              </div>
            )}
            <form className="catalogue-form" onSubmit={handleSubmit}>
              <div className="name-inputs">
                <div className="input-group">
                  <label htmlFor="name" className="label-name">
                    Full Name*
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Your Name..."
                    value={formData.name}
                    onChange={handleInputChange}
                    style={{ marginTop: "7px" }}
                    required
                    className="input-text"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="phone" className="label-name">
                    Mobile Number*
                  </label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={setPhone}
                    defaultCountry="IN"
                    id="phone"
                    name="phone"
                    style={{ marginTop: "7px" }}
                    required
                    className="input-phone"
                  />
                </div>
              </div>
              <div className="form-row">
                <label htmlFor="email" className="label-name">
                  Email*
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  style={{ marginTop: "7px" }}
                  placeholder="Enter Your Email ID"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="name-inputs">
                <div className="input-group">
                  <label htmlFor="companyName" className="label-name">
                    Company Name
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    style={{ marginTop: "7px" }}
                    placeholder="Enter Your Company Name..."
                    value={formData.companyName}
                    onChange={handleInputChange}
                    required
                    className="input-text"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="dateTime" className="label-name">
                    Date/Time Slot*
                  </label>
                  <div className="date-picker" style={{ marginTop: "7px" }}>
                    <DatePicker
                      selected={formData.dateTime}
                      onChange={handleDateTimeChange}
                      showTimeSelect
                      timeIntervals={60}
                      timeCaption="Time"
                      dateFormat="MMMM d, yyyy h:mm aa"
                      minTime={new Date(new Date().setHours(10, 0))}
                      maxTime={new Date(new Date().setHours(18, 0))}
                      required
                      className="custom-datepicker"
                    />
                  </div>
                </div>
              </div>

              <button type="submit" className="download-link">
                Ready to be Connected on Meet
              </button>
            </form>

            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
          </motion.div>
        </div>
      </div>
    </section>
  );
});

export default Freedemo;
