import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/hero.css";
import heroImage from "../assets/hero.png";
import ai from "../assets/icons/ai.png";
import timer from "../assets/icons/timer.png";
import { motion } from "framer-motion";

const Hero = ({ scrollToPricing }) => {
  const leftRightVariants = {
    hidden: { x: "-100vw", opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1.5 },
    },
  };

  // Variant for the down-up animation

  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLimitedTime, setIsLimitedTime] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    setIsLimitedTime(true);

    return () => clearInterval(timer);
  }, [currentTime]);

  const getTimeLeft = () => {
    const startOfMonth = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      1
    );
    const daysPassed =
      Math.floor((currentTime - startOfMonth) / (1000 * 60 * 60 * 24)) + 1;
    const daysInPeriod = 2 - ((daysPassed - 1) % 2); // Change from 10 to 2-day period
    const endOfPeriod = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate() + daysInPeriod,
      23,
      59,
      59
    );

    const timeLeft = endOfPeriod - currentTime;
    if (timeLeft <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    return {
      days: days.toString().padStart(2, "0"),
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };
  };

  const { days, hours, minutes, seconds } = getTimeLeft();

  const handleStartNow = () => {
    const token = localStorage.getItem("token");

    if (token) {
      // Scroll to PricingCards
      scrollToPricing();
    } else {
      // Navigate to the Login page
      navigate("/login");
    }
  };

  return (
    <section className="hero-container">
      <div className="hero-content">
        <motion.div className="hero-text" variants={leftRightVariants}>
          <h2 className="subheading">
            Everything to Bring Your Business to Life
          </h2>
          <p className="hero-quote">
            <span style={{ color: "#00a5e0" }}>With free</span>{" "}
            <span style={{ color: "#26017b" }}>Hosting</span>{" "}
            <span style={{ color: "#00a5e0" }}>for 2 Years + </span>
            <span style={{ color: "#26017b" }}>1 Month Free Plan</span>
          </p>
          <p className="details">
            <ul className="custom-list">
              <li>
                Ai Integration{" "}
                <span>
                  <img
                    src={ai}
                    alt="ai"
                    style={{
                      position: "relative",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </span>
              </li>
              <li>24/7 Customer Support</li>
              <li>Digital Marketing and SEO</li>
            </ul>
          </p>
          <p className="pricing">
            <span className="discounted-price">₹ 5999/mo</span>
          </p>
          <p style={{ marginTop: "5px" }}>
            <span
              style={{
                color: "#26017b",
                marginTop: "1px",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              +1 Month Free*
            </span>
          </p>
          <div className="hero-buttons">
            <button className="start-now" onClick={handleStartNow}>
              Start Now
            </button>
            {isLimitedTime && (
              <button className="timer">
                {days} : {hours} : {minutes} : {seconds}
              </button>
            )}
          </div>
          {isLimitedTime && (
            <div className="limited-time-deal">
              <span>
                <img
                  src={timer}
                  alt="timer"
                  style={{ width: "20px", height: "20px" }}
                />
              </span>{" "}
              <span>Limited time deal*</span>
            </div>
          )}
        </motion.div>
        <div className="hero-image" variants={leftRightVariants}>
          <img src={heroImage} alt="Hero" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
